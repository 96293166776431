@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/functions.sass";

html, body, #root, #main-wrapper {
    /* These help position the footer at the bottom of the page */
    height: 100%;
    display: block;
    width: 100%;
    min-width: 1024px;
}

#main-wrapper {
    /* These help position the footer at the bottom of the page */
    display: flex;
    flex-direction: column;
}

// Disable small screens
$mobile: 1px;
$tablet: 1px;
$desktop: 1px;

$danger: hsl(348, 55%, 47%);
$yellow: hsl(44deg 64.84% 51.59%);

$dark: $grey-darker;
$dark-bis: $grey-dark;
$dark-ter: $black-ter;

$light: $white-ter;
$light-bis: $white-bis;
$light-ter: $white;

$primary: #c59e1e;
$info: hsl(207deg 39.33% 44.45%);

$scheme-main: $dark;
$scheme-main-bis: $dark-bis;
$scheme-main-ter: $dark-ter;
$scheme-invert: $light;
$scheme-invert-bis: $light-bis;
$scheme-invert-ter: $light-ter;

$body-background-color: $dark;
$background: $dark;
$text: $light;
$text-invert: $dark;
$text-light: $light-ter;
$text-strong: $light-bis;
$border: $grey;
$shadow: 0;


$link: $light;
$link-invert: $light-ter;
$link-focus-border: $primary;
$link-hover: $text;
$link-hover-border: $primary;

$input-background-color: $dark-bis;
$input-disabled-border-color: $dark-bis;

$modal-background-background-color: bulmaRgba($scheme-main-ter, 0.86);

$box-background-color: $scheme-main-bis;
$box-link-hover-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 1px $primary;
$box-link-active-shadow: inset 0 1px 2px rgba($scheme-invert, 0.2), 0 0 0 1px $link;

@import '~bulma/bulma.sass'